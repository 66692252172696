<template>
  <footer
    class="app-footer"
    :class="{ 'is-reduced': isReduced }"
  >
    <div class="app-footer__content">
      <nav
        v-if="!isReduced"
        class="app-footer__nav-top"
      >
        <div
          v-for="(link_block, index) in footerSettings.footer_navigation_top"
          :key="link_block._uid"
          class="app-footer__link-block"
          :class="`item-${index}`"
        >
          <div class="app-footer__link-block-title">
            {{ link_block.title }}
          </div>
          <ul class="app-footer__links-top">
            <li v-for="link in link_block.links" :key="link._uid">
              <SlotLink :data="link">
                {{ link.label }}
              </SlotLink>
            </li>
          </ul>
        </div>
      </nav>

      <div
        v-if="isReduced"
        class="app-footer__button-reduced"
      >
        <SlotLink
          v-if="footerSettings?.footer_reduced_button_link?.cached_url"
          :data="footerSettings?.footer_reduced_button_link"
          class="app-footer__links-middle-icon"
        >
          <AtomButton :text="footerSettings?.footer_reduced_button_text" />
        </SlotLink>
      </div>

      <nav v-if="!isReduced" class="app-footer__nav-middle">
        <div
          v-for="(link_block, index) in footerSettings.footer_navigation_middle"
          :key="link_block._uid"
          class="app-footer__link-block"
          :class="`item-${index}`"
        >
          <div class="app-footer__link-block-title">
            {{ link_block.title }}
          </div>
          <div class="app-footer__links-middle">
            <SlotLink
              v-for="link in link_block.links"
              :key="link._uid"
              :data="link"
              class="app-footer__links-middle-icon"
            >
              <AppIcon :icon="link.icon" :is-raw="true" />
            </SlotLink>
          </div>
        </div>
      </nav>

      <nav class="app-footer__nav-bottom">
        <div class="app-footer__copyright">
          {{ footerSettings.footer_copyright_text }} {{ new Date().getFullYear() }}
        </div>
        <div class="app-footer__links-wrapper">
          <SlotLink
            v-for="link in footerSettings.footer_navigation_bottom"
            :key="link._uid"
            :data="link"
            class="app-footer__links-bottom"
          >
            {{ link.label }}
          </SlotLink>
        </div>
      </nav>
    </div>
    <div class="app-footer__newsletter">
      <div class="app-footer__newsletter-pretitle">
        {{ footerSettings.footer_newsletter_pretitle }}
      </div>
      <div class="app-footer__newsletter-title">
        {{ footerSettings.footer_newsletter_title }}
      </div>
      <FormController
        v-if="footerForm && footerForm.length === 1"
        class="app-footer__newsletter-form"
        :data="footerForm[0]"
        :is-loading="isLoading"
        @on-submit="formAction"
        @has-content="setHasContent"
      >
        <AtomButton
          button-type="submit"
          :is-disabled="isLoading || !hasContent"
          :text="footerForm[0].button_text"
        />
      </FormController>
    </div>
  </footer>
</template>

<script setup>
defineProps({
    isReduced: {
        type: Boolean,
        default: false,
    },
});
const footerSettings = computed(() => getStoryblokSetting(
    'footer',
));

const footerForm = computed(() => getStoryblokSetting(
    'footer',
    'footer_newsletter_form',
));

/* Newsletter Form */
const formRef = ref(null);
const {
    isLoading,
    send,
} = useFormAction(formRef, false);

const hasContent = ref(false);
const setHasContent = (value) => {
    hasContent.value = value;
};

const formAction = (values) => {
    send(async () => {
        const submitValues = { ...values };
        submitValues.type = 'subscribe';
        try {
            await useFetch('/api/mailchimp_signup', {
                method: 'POST',
                body: submitValues,
            });
        } catch (e) {
            useSentryError(e);
        }
    }, values);
};
</script>

<style lang="scss">
.app-footer__content {
    @include fluid('padding-top', 51px, 81px);
    @include fluid('padding-bottom', 47px, 81px);
    @include grid-columns(1, 10);
    position: relative;
    padding-right: 20px;
    background-color: $C_GREEN_BRIGHT;

    @include fluid('border-top-right-radius', 75px, 225px);
    // border-top-right-radius: 225px;

    .is-reduced & {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &:after {
        position: absolute;
        top: 0;
        left: -20%;
        width: 20%;
        height: 100%;
        background-color: $C_GREEN_BRIGHT;
        content: '';

    }

    @include mobile {
        @include grid-columns(1, 8);
    }
}

.app-footer__button-reduced {
    @include fluid('margin-bottom', 25px, 60px);
}

.app-footer {
    @include typo-font('regular');
    @include grid-layout(18);
    @include fluid('padding-left', 20px, 91px);
    @include fluid('border-top-right-radius', 75px, 225px);
    overflow: hidden;
    margin-right: var(--page-padding);
    background-color: $C_PRIMARY;

    @media screen and (max-width: 1520px) {
        margin-right: 0;
    }

    @include tablet-portrait{
        display: flex;
        flex-direction: column;
    }

    .app-footer__nav-top {
        @include grid-layout(10);
        margin-bottom: 30px;

        @include tablet-portrait{
            flex-direction: column;
            margin-bottom: 14px;
        }

        .app-footer__link-block-title {
            @include typo-size('p');
            margin-bottom: 10px;
            color: $C_GREEN_DARK;
        }

        li {
            @include typo-size('form');
            color: $C_SECONDARY;
            line-height: 170%;
            transition: color 0.1s ease-in-out;

            &:hover {
                color: $C-PRIMARY;
            }
        }
    }

    .app-footer__link-block {

        &.item-0 {
            @include grid-columns(1, 3);

            @include tablet-portrait {
                @include grid-columns(1, 2);
            }
        }
        &.item-1 {
            @include grid-columns(4, 3);

            @include tablet-portrait {
                @include grid-columns(3, 2);
            }
        }
        &.item-2 {
            @include grid-columns(7, 3);

            @include tablet-portrait {
                @include grid-columns(5, 3);
            }
        }

        @include tablet-portrait {
            margin-bottom: 14px;
        }
    }

    .app-footer__nav-middle {
        @include grid-layout(10);
        margin-bottom: 30px;

        @include tablet-portrait{
            flex-direction: column;
            margin-bottom: 14px;
        }

        .app-footer__link-block-title {
            @include typo-size('p');
            margin-bottom: 16px;
            color: $C_GREEN_DARK;
        }

        .app-footer__links-middle {
            display: flex;
            gap: 20px;
        }
    }

    .app-footer__nav-bottom {
        display: flex;
        align-items: center;
        color: $C_SECONDARY;

        .app-footer__links-wrapper {
            display: flex;
            flex-wrap: wrap;
        }

        .app-footer__links-bottom {
            margin-right: 30px;
            transition: color 0.1s ease-in-out;

            &:hover {
                color: $C-PRIMARY;
            }
        }

        @include tablet {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .app-footer__copyright {
        @include typo-size('p');
        margin-right: 20px;
        color: $C_GREEN_DARK;

        @include tablet {
            margin-bottom: 5px;
        }
        @include tablet-portrait {
            margin-bottom: 14px;
        }
    }

    .app-footer__newsletter {
        @include fluid('padding-top', 40px, 125px);
        @include fluid('padding-right' , 20px, 47px);
        @include fluid('padding-bottom', 52px, 77px);
        @include grid-columns(11, 8);
        color: $C_GREEN_DARK;

        .app-footer__newsletter-title {
            @include typo-size('h2');
            @include typo-font('black');
            @include fluid('margin-bottom', 20px, 30px);
        }

        .app-footer__newsletter-pretitle {
            @include typo-size('p');
            @include typo-font('regular');
        }

        .app-footer__newsletter-form {
            margin: 0;

            .form-input__label{
                @include typo-font('regular');

                color: $C-WHITE;
            }
        }

        .atom-heading {
            margin-bottom: 30px;
        }

        .form-input__element {
            display: flex;
            justify-content: flex-end;
            border: 2px solid $C_WHITE;
            border-radius: 6px;
            margin-bottom: 20px;
            background: transparent;

            &:focus {
                border-color: $C_WHITE;
            }
        }

        .atom-button {
            width: auto;
            border: none;
            margin: 0;
            background-color: $C_WHITE;
            color: $C_SECONDARY;
        }

        @include tablet-portrait {
            // @include fluid('padding-left', 20px, 91px);
            padding-left: 0;
        }
    }
}

</style>
